<template>
  <div>
    <a-avatar
      :size="size ? size : 'default'"
      v-if="thumbnailUrl"
      :src="thumbnailUrl.url"
      shape="square"
    />
    <a-avatar :size="size ? size : 'default'" shape="square" icon="loading" v-else />
  </div>
</template>

<script>
import helpVideos from "../../../../api/guidance/help-videos";
export default {
  props: ["tenantId", "helpVideo", "size"],
  data() {
    return {
      isLoadingThumbnailUrl: false,
      thumbnailUrl: null,
    };
  },
  created() {
    this.getThumbnailUrl();
  },
  methods: {
    getThumbnailUrl() {
      let vm = this;
      vm.isLoadingThumbnailUrl = true;
      helpVideos
        .getHelpVideoThumbProtectedUrl(this.tenantId, this.helpVideo["$v"]["id"])
        .then((r) => {
          vm.isLoadingThumbnailUrl = false;
          vm.thumbnailUrl = r.data;
        })
        .catch((e) => {
          console.log(e);
          // vm.$message.error("Error loading image");
        });
    },
  },
};
</script>

<style>
</style>