<template>
  <div class="current-help-video">
    <div class="left">
      {{ description }}
    </div>
    <div class="right">
      <a-button v-if="!isMarkedForDeletion" icon="delete" type="danger" @click.prevent="toggleDelete">Delete</a-button>
      <a-button v-if="isMarkedForDeletion" icon="delete" type="danger" @click.prevent="toggleDelete">Don't Delete</a-button>
      <p v-if="isMarkedForDeletion">
        This video will be deleted
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["media", "idsOfMediaToDelete"],
  methods: {
    toggleDelete() {
      this.$emit('toggleDeleting', this.media['$v']['id']);
    }
  },
  computed: {
    isMarkedForDeletion() {
      return this.idsOfMediaToDelete.includes(this.media['$v']['id'])
    },

    description() {
      let description = "";
      let m = this.media;

      if (m["$v"]["available"]) {
        description = description + "Available";
      } else {
        description = description + "Unavailable";
      }

      if (m["$v"]["orientation"] == 1) {
        description = description + " | Portrait";
      } else {
        description = description + " | Landscape";
      }

      if (m["$v"]["contentType"] == "text/html") {
        description = description + " | Web embedded";
      } else {
        description =
          description +
          " | " +
          (m["$v"]["byteSize"] ? m["$v"]["byteSize"] : "unknown") +
          " bytes";
      }

      return description;
    },
  },
};
</script>

<style scoped lang="scss">
.current-help-video {
  display: flex;
  padding: 18px;
  border: 1px solid #eee;
  border-radius: 6px;

  .left {
    flex-grow: 1;
  }
  .right {
    flex-shrink: 1;
    text-align: right;

    p {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
}
</style>